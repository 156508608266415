import React from "react";
import { useTranslation, Trans } from "react-i18next";
import Card from "../../components/card/card";
import ButtonPrimary from "../../../../shared/components/button-primary/button-primary";
import HomeBanner from "./home-banner/home-banner";
import Subsection from "../../components/subsection/subsection"
import SubsectionSecondary from "../../components/subsection-secondary/subsection-secondary"
import SwiperComponent from "../../../../shared/components/swiper-template/swiper-template";
import SwiperComponentSecondary from "../../../../shared/components/swiper-template-secondary/swiper-template-secondary";
import CardTestimony from "./card-testimony/card-testimony";
import testimony1 from "../../assets/images/testimony1.jpg";
import testimony2 from "../../assets/images/testimony2.png";
import testimony3 from "../../assets/images/testimony3.jpg";
import imageSwiperSecondary1 from "../../assets/swipersecondary/booste-reseau.jpg";
import imageSwiperSecondary2 from "../../assets/swipersecondary/affine-projet-pro.jpg";
import imageSwiperSecondary3 from "../../assets/swipersecondary/organise-events.jpg";
import shadeHeader from "../../assets/images/shade2.svg";
import "./home.scss";

const videoUrl = "https://www.youtube.com/watch?v=0FIicIwYvgs&t=7s";

const CardList = [
    {
        title: "home.card.network.title",
        text: "home.card.network.content",
        id: "network",
    },
    {
        title: "home.card.job.title",
        text: "home.card.job.content",
        id: "job",
    },
    {
        title: "home.card.school.title",
        text: "home.card.school.content",
        id: "school",
    },
];

const SubsectionSecondaryList = [
    {
        title: "home.subsectionSecondary.content1.title",
        text: "home.subsectionSecondary.content1.text",
        imageSrc: imageSwiperSecondary1,
        id: "content1",
    },
    {
        title: "home.subsectionSecondary.content2.title",
        text: "home.subsectionSecondary.content2.text",
        imageSrc: imageSwiperSecondary2,
        id: "content2",
    },
    {
        title: "home.subsectionSecondary.content3.title",
        text: "home.subsectionSecondary.content3.text",
        imageSrc: imageSwiperSecondary3,
        id: "content3",
    },
];

const Home = () => {
    const { t } = useTranslation();
    const linkedinCapgemini = "https://www.linkedin.com/feed/hashtag/?keywords=capgeministudentconnection";
    const linkedinPost1 =
        "https://www.linkedin.com/posts/lukas-houille_capgeministudentconnection-networking-formation-activity-7176499245514944514-a4Jr?utm_source=share&utm_medium=member_desktop";
    const linkedinPost2 =
        "https://www.linkedin.com/posts/divine-emmanuella-ekedem-dontsop-31b084250_womenatcapgemini-getthefutureyouwant-capgeministudentconnection-activity-7120492264992256000-Ce8A?utm_source=share&utm_medium=member_desktop";
    const linkedinPost3 =
        "https://www.linkedin.com/posts/hugodufauredelajarte_sustainability-capgemini-rsechallenge-activity-7173629274774605824-fDX2?utm_source=share&utm_medium=member_desktop";

    return (
        <div className="home">

            <HomeBanner />

            <section className="home-inscription">
                <div className="home-inscription__wrapper">
                    <Trans parent="h2" i18nKey="home.inscription.text" />
                    <ButtonPrimary tertiary path="/join">Inscription</ButtonPrimary>
                </div>
            </section>

            <SwiperComponent className="swiper-cards">
                {CardList.map((card) => (
                    <Card
                        className={`home__card-list__item home__card-list__item--${card.id}`}
                        title={card.title}
                        text={card.text}
                        key={card.id}
                    />
                ))}
            </SwiperComponent>

            <Subsection
                mediaType="video"
                mediaUrl={videoUrl}
                textTitle={
                    <>
                        Découvre l&apos;incroyable année que tu vas passer en tant qu&apos;<span style={{ fontStyle: "italic", fontWeight: 400 }}>ambassadeur Capgemini !</span>
                    </>
                }
            />

            <div className="home-swiper-component-secondary">
                <SwiperComponentSecondary className="swiper-secondary">
                    {SubsectionSecondaryList.map((subsectionSecondary) => (
                        <SubsectionSecondary
                            key={subsectionSecondary.id}
                            title={subsectionSecondary.title}
                            text={subsectionSecondary.text}
                            buttonText={subsectionSecondary.buttonText}
                            buttonLink={subsectionSecondary.buttonLink}
                            imageSrc={subsectionSecondary.imageSrc}
                        />
                    ))}
                </SwiperComponentSecondary>
                <img src={shadeHeader} alt="Logo" className="shade" />
            </div>


            <div className="home__testimony">
                <a className="home__testimony__title-link" href={linkedinCapgemini}>
                    <h2 className="home__testimony__title">{t("home.linkedInPost.title")}</h2>
                </a>
                <SwiperComponent className="swiper-card-testimony home__testimony__wrapper">
                    <a className="home__testimony__link home__linkedInPost__link" href={linkedinPost1} target="_blank" rel="noreferrer">
                        <CardTestimony
                            name="home.linkedInPost.content.0.name"
                            job="home.linkedInPost.content.0.job"
                            text="home.linkedInPost.content.0.text"
                            profile={testimony1}
                        />
                    </a>
                    <a className="home__testimony__link home__linkedInPost__link" href={linkedinPost2} target="_blank" rel="noreferrer">
                        <CardTestimony
                            name="home.linkedInPost.content.1.name"
                            job="home.linkedInPost.content.1.job"
                            text="home.linkedInPost.content.1.text"
                            profile={testimony2}
                        />
                    </a>
                    <a className="home__testimony__link home__linkedInPost__link" href={linkedinPost3} target="_blank" rel="noreferrer">
                        <CardTestimony
                            name="home.linkedInPost.content.2.name"
                            job="home.linkedInPost.content.2.job"
                            text="home.linkedInPost.content.2.text"
                            profile={testimony3}
                        />
                    </a>
                </SwiperComponent>
            </div>
        </div>
    );
};

export default Home;
